import React from 'react';
import { Link } from 'gatsby';

import styled from 'styled-components';
import tw from 'tailwind.macro';

const PaginationStyles = styled.div`
  ${tw`flex items-center justify-center`}
`;

const Pagination = ({ urlPrefix, pageContext }) => {
  const { currentPage, numPages } = pageContext;
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === numPages;

  const prevPage = currentPage - 1 === 1 ? '/' : `${urlPrefix}/page/${currentPage - 1}`;
  const nextPage = `${urlPrefix}/page/${currentPage + 1}`;

  return (
    <PaginationStyles>
      {!isFirstPage && (
        <span className="text-silversand mr-20">
          <Link to={`${prevPage}`}>
            <svg className="fill-current" width="9" height="14" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.414 7l5.293 5.293-1.414 1.414L.586 7 7.293.293l1.414 1.414z" />
            </svg>
          </Link>
        </span>
      )}
      <span>
        {Array.from({ length: numPages }).map((_, index) => {
          return (
            <Link
              to={index === 0 ? urlPrefix : `${urlPrefix}/page/${index + 1}`}
              className={`mx-4 rounded font-medium text-center px-12 py-8 ${
                index + 1 === currentPage
                  ? 'bg-tealblue text-white'
                  : 'bg-porcelain text-silversand'
              }`}>
              {index + 1}
            </Link>
          );
        })}
      </span>
      {!isLastPage && (
        <span className="text-silversand ml-20">
          <Link to={`${nextPage}`} disabled={currentPage + 1 > numPages ? 'true' : null}>
            <svg className="fill-current" width="9" height="14" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.586 7L.293 12.293l1.414 1.414L8.414 7 1.707.293.293 1.707z" />
            </svg>
          </Link>
        </span>
      )}
    </PaginationStyles>
  );
};

export default Pagination;

import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import CategoryLabel from './CategoryLabel';
import { ArticleSnippetFeatured } from './ArticleSnippet';

const FeaturedBlog = ({ title, excerpt, slug, date, image, category, timeToRead }) => (
	<Link to={`/blog/${slug}`}>
		{image && (
			<Img fluid={image.childImageSharp.fluid} className="rounded h-200 md:h-260 lg:h-450" />
		)}
		<div className="lg:w-11/12 border-solid border-iron border-b pt-20 pb-40">
			<ArticleSnippetFeatured
				category={category}
				date={date}
				title={title}
				excerpt={excerpt}
				timeToRead={timeToRead}
			/>
		</div>
	</Link>
);

export default FeaturedBlog;

import React from 'react';

import { graphql, Link } from 'gatsby';

import Img from 'gatsby-image';

import Layout from 'components/layout';
import SEO from 'components/seo';
import FeaturedBlog from 'components/Blog/FeaturedBlog';
import Blog from 'components/Blog/Blog';

import Hero from 'components/Hero';
import Card from 'components/Card';

import { Primary as PrimaryButton } from 'components/Button';

import SafeAreaWrapper from '../components/SafeAreaWrapper';

import Pagination from 'components/Pagination';

import CategoryLabel from 'components/Blog/CategoryLabel';
import {
	ArticleSnippet,
	ArticleSnippetFeatured,
	ArticleSnippetSmall,
} from 'components/Blog/ArticleSnippet';

const IndexPage = ({
	location,
	pageContext,
	data: {
		blogs: { edges },
		heroBackgroundImage,
		productPortfolioImage,
		upcomingEvents,
	},
}) => {
	const Blogs = edges.map((edge, index) =>
		index === 0 && pageContext.currentPage === 1 ? (
			<FeaturedBlog
				key={edge.node.id}
				title={edge.node.frontmatter.title}
				excerpt={edge.node.frontmatter.excerpt}
				timeToRead={edge.node.fields.readingTime.text}
				slug={edge.node.frontmatter.slug}
				date={edge.node.frontmatter.date}
				image={edge.node.frontmatter.featureImage}
				category={edge.node.frontmatter.category}
			/>
		) : (
			<div className="border-solid border-iron border-b py-40 w-full lg:w-11/12">
				<Blog
					key={edge.node.id}
					title={edge.node.frontmatter.title}
					excerpt={edge.node.frontmatter.excerpt}
					timeToRead={edge.node.fields.readingTime.text}
					slug={edge.node.frontmatter.slug}
					date={edge.node.frontmatter.date}
					image={edge.node.frontmatter.featureImage}
					category={edge.node.frontmatter.category}
				/>
			</div>
		),
	);
	return (
		<Layout translucent="true">
			<SEO title="Blog" description="Hanseaticsoft Insights" />

			<Hero backgroundImage={heroBackgroundImage} location={location}>
				<div
					className="flex flex-col items-center justify-center h-full"
					style={{ backgroundColor: 'rgba(0, 70, 121, .6)' }}>
					<h1 className="text-center text-white">Insights</h1>
					<h1 className="text-center" style={{ color: '#B6C3CF' }}>
						Always be up to date
					</h1>
				</div>
			</Hero>

			<SafeAreaWrapper>
				<div className="container mx-auto px-20 md:px-0 py-120  pb-80 md:pb-100 lg:pb-120">
					{/* <div className="mb-50">
						<Link
							to="#"
							className={`rounded-full font-medium text-center mr-12 px-12 py-8 text-white bg-tealblue`}>
							All
						</Link>
						<Link
							to="#"
							className={`rounded-full font-medium text-center mr-12 px-12 py-8 text-white bg-silversand`}>
							News
						</Link>
						<Link
							to="#"
							className={`rounded-full font-medium text-center mr-12 px-12 py-8 text-white bg-silversand`}>
							Events
						</Link>
						<Link
							to="#"
							className={`rounded-full font-medium text-center mr-12 px-12 py-8 text-white bg-silversand`}>
							Features
						</Link>
					</div> */}

					<div className="grid grid-cols-12 md:gap-40">
						<div className="col-span-12 md:col-span-7 lg:col-span-8">
							<div>{Blogs}</div>
							<div className="mt-40 md:mt-50 xl:60 mb-80 md:mb-0">
								<Pagination urlPrefix="/blog" pageContext={pageContext} />
							</div>
						</div>

						<div className="col-span-12 md:col-span-5 lg:col-span-4">
							<Card
								category="Newsletter"
								title="Be always up to date"
								text="Join hundreds of fleet managers, inspectors and ship owners who read our news
                  first."
								button="Subscribe"
							/>

							{upcomingEvents && upcomingEvents.edges.length > 0 && (
								<div className="mt-60 mb-30">
									<h3 className="pb-10 border-solid border-iron border-b">
										Upcoming events
									</h3>

									{upcomingEvents.edges.map(edge => (
										<div className="mt-20 pb-20 border-solid border-iron border-b">
											<ArticleSnippetSmall
												key={edge.node.id}
												category={edge.node.frontmatter.category}
												date={edge.node.frontmatter.date}
												title={edge.node.frontmatter.title}
												excerpt={edge.node.frontmatter.excerpt}
											/>
										</div>
									))}
								</div>
							)}

							<div className="mt-60 ">
								<Card
									category="Brochure"
									title="Cloud Fleet Manager Product Portfolio"
									text="Learn in depth what Cloud Fleet Manager has to offer and how the cloud-based approach helps
    shipping companies."
									image={productPortfolioImage}
									button="Download"
								/>
							</div>
						</div>
					</div>
				</div>
			</SafeAreaWrapper>
		</Layout>
	);
};

export default IndexPage;

export const pageQuery = graphql`
	query BlogIndex($skip: Int! = 0, $limit: Int! = 10) {
		heroBackgroundImage: file(relativePath: { eq: "blog-background.png" }) {
			childImageSharp {
				fluid(quality: 60, maxWidth: 1920) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}

		productPortfolioImage: file(relativePath: { eq: "product-portfolio.png" }) {
			childImageSharp {
				fluid(quality: 60, maxWidth: 430) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}

		blogs: allMdx(
			filter: { isFuture: { eq: false }, frontmatter: { published: { eq: true } } }
			sort: { fields: frontmatter___date, order: DESC }
			skip: $skip
			limit: $limit
		) {
			edges {
				node {
					id
					fields {
						readingTime {
							text
						}
					}
					frontmatter {
						date(formatString: "MMMM DD, YYYY")
						excerpt
						slug
						title
						category
						featureImage {
							childImageSharp {
								fluid(maxWidth: 840, quality: 60) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}

		upcomingEvents: allMdx(
			filter: {
				isFuture: { eq: true }
				frontmatter: { published: { eq: true }, tags: { in: ["event"] } }
			}
			sort: { fields: frontmatter___date, order: ASC }
			limit: 5
		) {
			edges {
				node {
					id

					frontmatter {
						date(formatString: "MMMM DD, YYYY")
						excerpt
						slug
						title
						category
					}
				}
			}
		}
	}
`;

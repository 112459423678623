import React from 'react';

import Img from 'gatsby-image';
import CategoryLabel from './CategoryLabel';

const ArticleSnippetFeatured = ({ category, date, title, excerpt, timeToRead }) => (
	<>
		<CategoryLabel category={category} date={date}>
			{timeToRead && <span className="text-xs text-nevada"> &bull; {timeToRead}</span>}
		</CategoryLabel>
		<h3 className="mt-8 mb-8 lg:text-4xl lg:mb-12 lg:mt-12">{title}</h3>
		<p className="small">{excerpt}</p>
	</>
);

const ArticleSnippet = ({ category, date, title, excerpt, timeToRead }) => (
	<>
		<CategoryLabel category={category} date={date}>
			{timeToRead && <span className="text-xs text-nevada"> &bull; {timeToRead}</span>}
		</CategoryLabel>
		<h3 className="mt-8 mb-8">{title}</h3>
		<p className="small">{excerpt}</p>
	</>
);

const ArticleSnippetSmall = ({ category, date, title, excerpt }) => (
	<>
		<CategoryLabel category={category} date={date} />
		<p className="font-bold mt-8 mb-4">{title}</p>
		<p className="small">{excerpt}</p>
	</>
);

const ArticleSnippetTiny = ({ category, date, title, excerpt, featureImage }) => (
	<div
		className="flex flex-row group rounded duration-300 transition-all bg-right-bottom hover:bg-left-bottom"
		style={{
			backgroundSize: '200% 100%',
			backgroundImage:
				'linear-gradient(to right, rgba(255,255,255,0.5) 50%, rgba(0,0,0,0) 50%)',
		}}>
		{featureImage && (
			<Img fixed={featureImage.childImageSharp.fixed} className="flex-none rounded mr-12" />
		)}
		<div className="flex-grow">
			<CategoryLabel category={category} date={date} />
			<p className="relative text-md font-bold mt-4 group-hover:text-pictonblue duration-300 leading-26">
				{title}
			</p>
			{excerpt && <p className="small group-hover:text-pictonblue duration-300">{excerpt}</p>}
		</div>
		<div className="flex-none self-end m-6">
			<svg
				width="14"
				height="12"
				xmlns="http://www.w3.org/2000/svg"
				// flex-none self-end
				className="mx-8 mr-4 fill-current text-pictonblue">
				<path
					d="M14 6l-5.707 5.707-1.414-1.414L10.171 7H0V5h10.171L6.879 1.707 8.293.293z"
					fill-rule="evenodd"
				/>
			</svg>
		</div>
	</div>
);

export { ArticleSnippetFeatured, ArticleSnippet, ArticleSnippetSmall, ArticleSnippetTiny };

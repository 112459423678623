import React from 'react';

export default ({ category, date, children }) => (
	<>
		<span className="font-bold text-xs uppercase text-pictonblue">{category}</span>
		<span className="text-xs text-nevada ml-8 group-hover:text-pictonblue duration-300">
			{date}
		</span>
		{children}
	</>
);

import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import CategoryLabel from './CategoryLabel';
import { ArticleSnippet } from './ArticleSnippet';

const Blog = ({ title, excerpt, slug, date, image, category, timeToRead }) => (
	<Link to={`/blog/${slug}`}>
		<div className="grid grid-cols-1 gap-20 lg:grid-cols-12 lg:gap-30">
			<div className="lg:col-span-5">
				{image && (
					<div className="rounded overflow-hidden h-200 md:h-260 lg:h-240 relative z-10">
						<Img
							fluid={image.childImageSharp.fluid}
							className="rounded w-full h-full object-cover duration-500 transform hover:scale-125 z-0"
						/>
					</div>
				)}
			</div>

			<div className="lg:col-span-7">
				<ArticleSnippet
					category={category}
					date={date}
					title={title}
					excerpt={excerpt}
					timeToRead={timeToRead}
				/>
			</div>
		</div>
	</Link>
);

export default Blog;
